import React, { useEffect, useState } from 'react';
import useSendSubscription from '../hooks/useSendSubscription';
import { DoctContainer, DoctIcon, DoctTypography } from '@doct-react/core';
import FormFooterPaymentHeading from '../Form/FormFooterPaymentHeading';
import * as ROUTE from '../../../routes/constant';
import Accordion from '../CommonAccordion/Accordian';
import TotalPriceCard from './TotalPriceCard';
import CommonPackageDetailCard from '../CommonPackageDetailCard/CommonPackageDetailCard';
import { useSelector } from 'react-redux';
import { confirmSubscription } from '../helper/confirmSubscription';
import { prepObjectForFormData } from '../utility/prepObjectForFormSubmit';
import { EnterpriseFormLayout } from '../../../layout';
import { decryptData } from '../../../helper/helperFunction';
import { sendRollBackHistory } from '../helper/sendRollBackHistory';
import { Toaster } from '../../../shared';
import style from './OrderScreen.module.scss';
import { DoctAnimationLoading } from '@doct-react/app';

const OrderScreen = () => {
  const { navigate } = useSendSubscription();
  const forFreePlanRedirectURL = '/dashboard/billing-subscription/subscription';
  const userDetails = useSelector((state) => state?.app?.user);

  const [successDetails, setSuccessDetails] = useState(null);
  const [storedAmountDetails, setStoredAmountDetails] = useState(null);
  const [storedPlanName, setStoredPlanName] = useState('');
  const [bundleName, setBundleName] = useState('');
  const [planName, setPlanName] = useState('');
  const [storedPrepDatas, setStoredPrepDatas] = useState(null);
  const [storedPlanId, setStoredPlanId] = useState('');
  const [storedBundle, setStoredBundle] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSaveUpTo, setIsSaveUpTo] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [redirectLoading, setRedirectLoading] = useState(false);

  useEffect(() => {
    const encryptedSuccessDetails = localStorage.getItem('successDetails');
    if (encryptedSuccessDetails) {
      const decryptedData = decryptData(encryptedSuccessDetails);

      const parsedSuccessDetails = JSON.parse(decryptedData);
      setSuccessDetails(parsedSuccessDetails);
    }

    const encryptedAmountDetails = localStorage.getItem('amountDetails');
    if (encryptedAmountDetails) {
      const decryptedData = decryptData(encryptedAmountDetails);
      const parsedAmountDetails = JSON.parse(decryptedData);
      setStoredAmountDetails(parsedAmountDetails);
    }

    const encryptedPrepDatas = localStorage.getItem('prepDatas');
    if (encryptedPrepDatas) {
      const decryptedData = decryptData(encryptedPrepDatas);
      const parsedPrepDatas = JSON.parse(decryptedData);
      setStoredPrepDatas(parsedPrepDatas);
    }
    const encryptedData = localStorage.getItem('encryptedPlanData');
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      setStoredPlanId(decryptedData.id);
    }

    const encryptedSubscriptionPlanData = localStorage.getItem('encryptedSubscriptionPlanData');
    if (encryptedSubscriptionPlanData) {
      const decryptedData = decryptData(encryptedSubscriptionPlanData);
      if (decryptedData.bundleId) {
        setStoredBundle(decryptedData);
      }
    }
  }, []);

  useEffect(() => {
    const encryptedData = localStorage.getItem('encryptedPlanData');
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      setIsSaveUpTo(decryptedData.saveUpTo);
      setStoredPlanName(decryptedData.name);
    }
    const encryptedSubscriptionPlanData = localStorage.getItem('encryptedSubscriptionPlanData');
    if (encryptedSubscriptionPlanData) {
      const decryptedData = decryptData(encryptedSubscriptionPlanData);

      setIsSaveUpTo(decryptedData?.saveUpTo);
      setBundleName(decryptedData.bundleName);
      setPlanName(decryptedData.planName);
    }
  }, []);

  // Convert the date strings to Date objects
  // const start = new Date(successDetails?.plan?.startDate);
  // const expiry = new Date(successDetails?.plan?.expiryDate);

  // Calculate the difference in milliseconds between the two dates
  // const timeDiff = expiry.getTime() - start.getTime();

  // Convert milliseconds to years
  // const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Consider leap years
  // const years = Math.floor(timeDiff / millisecondsPerYear);
  const { token } = useSelector((state) => state.app);

  prepObjectForFormData.subscriberBillingInformation = storedPrepDatas;

  prepObjectForFormData.subscriberOrder.subscriberBillingInformation = storedPrepDatas;

  prepObjectForFormData.callbackUrl = `${ROUTE.ENTERPRISE_DOCTHUB_URL}/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.CONFIRMATION_ROUTE}`;
  const getPlanDurationText = () => {
    const duration = successDetails?.plan?.duration;

    if (parseInt(duration) < 0) {
      return 'Lifetime';
    } else if (duration === '12 Month') {
      return '1 Year Access';
    } else {
      return `${duration} ${successDetails?.plan?.durationType}`;
    }
  };

  const getSubscriptionBundleById = (id) => {
    return storedAmountDetails?.plan?.subscriptionBundle?.find((bundle) => bundle?.id === id);
  };

  const subscriptionBundle = getSubscriptionBundleById(storedBundle?.bundleId);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
        window.location.assign(forFreePlanRedirectURL);
      }, 3000);
    }
  }, [isSuccess]);

  const objForsendRollBackHistory = {
    userId: userDetails?.id,
    userNumber: userDetails?.userNumber,
    userName: userDetails?.name,
    email: userDetails?.email,
    status: 'order summary',
    subscriptionPacakgeId: storedBundle?.bundleId,
  };

  const handleRedirect = () => {
    if (
      location.href.includes('recruiter-dashbord') &&
      storedAmountDetails?.plan?.id &&
      storedAmountDetails?.plan?.subscriptionBundleDto?.id
    ) {
      navigate(
        `/dashboard/billing-subscription/subscription/all-packages/billing-info-recruiter/rBundleId?=${storedAmountDetails?.plan?.subscriptionBundleDto?.id}`,
        {
          state: {
            bundleId: storedAmountDetails?.plan?.subscriptionBundleDto?.id,
            planId: storedAmountDetails?.plan?.id,
          },
        },
      );
    } else if (storedBundle?.bundleId) {
      sendRollBackHistory(objForsendRollBackHistory, token);
    } else {
      navigate(
        `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}`,
      );
    }
  };

  const handleProceedToPay = async () => {
    const showAlert =
      userDetails?.tenant?.status !== 'Active' || !userDetails?.tenant?.organizationId;
    if (showAlert) {
      setShowAlert(true);
      return;
    }
    // revert the changes of 4991 bug and re implement solution for this.
    await confirmSubscription(
      setShowLoader,
      storedBundle,
      storedPlanId,
      prepObjectForFormData,
      token,
      setIsSuccess,
    );
  };

  useEffect(() => {
    let timerID;
    if (showAlert) {
      timerID = setTimeout(() => {
        setShowAlert(false);
        navigate(-1);
      }, 20000); // for longer visible duration of alert
    }
    return () => {
      clearTimeout(timerID);
    };
  }, [showAlert]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setRedirectLoading(true); // Show loader
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (redirectLoading) {
    return <DoctAnimationLoading />;
  }

  return (
    <>
      <div className="position-fixed tost-container-top-right">
        {isSuccess ? (
          <Toaster
            variant={isSuccess ? 'positive' : 'danger'}
            text={isSuccess ? 'Free plan activated successfully!' : 'Oops! something went wrong'}
            onPressedClose={() => setIsSuccess(false)}
          />
        ) : (
          ''
        )}
      </div>
      <div className="position-fixed tost-container-top-right">
        {showAlert && (
          <Toaster
            variant={'information-error'}
            text={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Your account/organization is still in the process of verification.
          <br/>To proceed with purchasing a plan, please connect with us <br /> at <b>+91 95104 03130<b> OR <b>enterprise@docthub.com.<b>
          <br/>We appreciate your patience. Thank you.`,
                }}
              />
            }
            onPressedClose={() => {
              setShowAlert(false);
              navigate(-3);
            }}
          />
        )}
      </div>
      <EnterpriseFormLayout
        formDisclaimer={
          <FormFooterPaymentHeading
            amount={storedAmountDetails?.amount?.totalAmount}
            currency="INR"
          />
        }
        formPrimaryBtnText={`${
          storedPlanName === 'Institute Free Plan' ? 'Submit' : 'Proceed to Pay'
        }`}
        isAdding={showLoader}
        primaryButtonType={'semantic-success'}
        showFormFooterSecondaryBtn={true}
        formFooterSecondaryBtnText="Back"
        formTitle={`${planName || bundleName ? `${planName} => ${bundleName}` : storedPlanName} `}
        closeHandler={() => {
          handleRedirect();
          // Note:- currently this changes are committed until QA verified.
          // if (
          //   location.href.includes('recruiter-dashbord') &&
          //   storedAmountDetails?.plan?.id &&
          //   storedAmountDetails?.plan?.subscriptionBundleDto?.id
          // ) {
          //   navigate(
          //     `/dashboard/billing-subscription/subscription/all-packages/billing-info-recruiter/rBundleId?=${storedAmountDetails?.plan?.subscriptionBundleDto?.id}`,
          //     {
          //       state: {
          //         bundleId: storedAmountDetails?.plan?.subscriptionBundleDto?.id,
          //         planId: storedAmountDetails?.plan?.id,
          //       },
          //     },
          //   );
          // } else if (storedBundle?.bundleId) {
          //   sendRollBackHistory(objForsendRollBackHistory, token);
          // } else {
          //   navigate(
          //     `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}`,
          //   );
          // }
        }}
        handleSubmit={async () => {
          await handleProceedToPay();
          // Note:- currently this changes are committed until QA verified.
          // const showAlert =
          //   userDetails?.tenant?.status !== 'Active' || !userDetails?.tenant?.organizationId;
          // if (showAlert) {
          //   setShowAlert(true);
          //   return;
          // }
          // // revert the changes of 4991 bug and re implement solution for this.
          // await confirmSubscription(
          //   setShowLoader,
          //   storedBundle,
          //   storedPlanId,
          //   prepObjectForFormData,
          //   token,
          //   setIsSuccess,
          // );
        }}
        iconName="arrowBack"
        isConfirmationScreen={true}
        showSecondaryHeaderBtn={false}
        formFooterSecondaryBtnClickHandler={() => {
          handleRedirect();
          // Note:- currently this changes are committed until QA verified.
          // if (location.href.includes('recruiter-dashbord')) {
          //   navigate(
          //     `/dashboard/billing-subscription/subscription/all-packages/billing-info-recruiter/rBundleId?=${storedAmountDetails?.plan?.subscriptionBundleDto?.id}`,
          //     {
          //       state: {
          //         bundleId: storedAmountDetails?.plan?.subscriptionBundleDto?.id,
          //         planId: storedAmountDetails?.plan?.id,
          //       },
          //     },
          //   );
          // } else if (storedBundle?.bundleId) {
          //   sendRollBackHistory(objForsendRollBackHistory, token);
          // } else {
          //   navigate(
          //     `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}`,
          //   );
          // }
        }}
      >
        <DoctContainer>
          <CommonPackageDetailCard
            packageTitle="Order summary"
            subscriptionPlanName={`${successDetails?.plan?.name} Subscription`}
            accordionBody={
              <Accordion title={`${successDetails?.plan?.name}`}>
                <div className="d-flex flex-column">
                  {/* coursre listing */}
                  {successDetails?.plan?.subscriptionPackage?.name === 'Institute Dashboard' && (
                    <div className={`m-0 text-grey-600 ml-2`}>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {successDetails?.plan?.subscriptionPlanFeature?.courseApplicantMode ===
                        'Unlimited'
                          ? 'Unlimited'
                          : successDetails?.plan?.subscriptionPlanFeature?.courseApllicantViews}
                        &nbsp;Course Applicants Views
                      </li>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {successDetails?.plan?.subscriptionPlanFeature?.cousreListing === 0
                          ? successDetails?.plan?.subscriptionPlanFeature?.courseMode
                          : `${successDetails?.plan?.subscriptionPlanFeature?.cousreListing}`}
                        &nbsp;Course listing
                      </li>
                    </div>
                  )}
                  {/* recruiter listing */}
                  {successDetails?.plan?.subscriptionPackage?.name === 'Recruiter Dashboard' && (
                    <div className={`m-0 text-grey-600 ml-2`}>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriptionBundle?.jobMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriptionBundle?.numberOfJob}
                        &nbsp;Job Posting
                      </li>
                      <li className={`m-0 text-grey-600`}>
                        <DoctIcon width="10" name="check" className="mr-2" fill="#707070" />
                        {subscriptionBundle?.jobApplicantMode === 'Unlimited'
                          ? 'Unlimited'
                          : subscriptionBundle?.numberOfApplicants}
                        &nbsp;Job Applicants Views
                      </li>
                    </div>
                  )}
                </div>
              </Accordion>
            }
          >
            <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
              {successDetails?.plan?.name}
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0 text-grey-600">
              &nbsp; . &nbsp;
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0 mt-1 text-grey-600">
              {getPlanDurationText()}
            </DoctTypography>
          </CommonPackageDetailCard>

          <div className={`${style['total-price-card-body']} pl-4 pr-4 pt-2 pb-2 mt-3`}>
            <TotalPriceCard
              text="PLAN PRICE"
              amount={`INR ${storedAmountDetails?.amount?.netAmount}`}
            />
            <div className={`${style['text-line-divider']}`} />
            {storedAmountDetails?.amount?.discountAmount == 0 ? (
              <></>
            ) : (
              <TotalPriceCard
                text={
                  isSaveUpTo
                    ? `DISCOUNT`
                    : `DISCOUNT (${
                        storedAmountDetails?.plan?.discountPercent ||
                        subscriptionBundle?.discountPercent
                      }%)`
                }
                amount={`- INR ${storedAmountDetails?.amount?.discountAmount}`}
                discountedAmount={true}
              />
            )}

            <div className={`${style['text-line-divider']}`} />

            {storedAmountDetails?.amount?.billingState === 'Gujarat' ||
            storedAmountDetails?.amount?.gstNumber?.startsWith('24') ? (
              <>
                <TotalPriceCard
                  text="CGST(9%)"
                  amount={`+ INR ${storedAmountDetails?.amount?.cgst}`}
                />
                <div className={`${style['text-line-divider']}`} />
                <TotalPriceCard
                  text="SGST(9%)"
                  amount={`+ INR ${storedAmountDetails?.amount?.sgst}`}
                />
              </>
            ) : (
              <TotalPriceCard
                text="IGST(18%)"
                amount={`+ INR ${storedAmountDetails?.amount?.igst}`}
              />
            )}

            <TotalPriceCard
              text="Total Payable Amount"
              amount={`INR ${storedAmountDetails?.amount?.totalAmount}`}
              totalAmount={true}
            />
          </div>
          <div className="pt-5"></div>
        </DoctContainer>
      </EnterpriseFormLayout>
    </>
  );
};

export default OrderScreen;
